import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Largo
{
    largoId?:number = 0;
    descripcion?:string ="";
    pulgada?:number =0;
    pie?:number =0;
    estado?:boolean = false;
    descripcionPulgada?: string;
    pulgadaComercial?: number;
    descripcionPulgadaComercial?: string;

    constructor(_largoId?:number,_descripcion?:string,_pulgada?:number, 
        _pie?:number, _estado?:boolean,_descripcionPulgada?:string,
        _pulgadaComercial?:number,descripcionPulgadaComercial?:string)
    {
        this.largoId = _largoId;
        this.descripcion  =_descripcion;
        this.pulgada = _pulgada;
        this.pie = _pie;
        this.estado = _estado;
        this.descripcionPulgada = _descripcionPulgada;
        this.pulgadaComercial =_pulgadaComercial;
        this.descripcionPulgadaComercial = descripcionPulgadaComercial;

    }
}

async function  GuardarLargo(largo:Largo):Promise<ResponseGenerico>
{
    return await Save<Largo>("largo/Guardar",largo,mainAxios);
} 

async function  EditarLargo(largo:Largo):Promise<ResponseGenerico>
{
    return await Editar<Largo>("largo/Actualizar",largo,mainAxios);
} 

async function  EditarEstadoLargo(largo:Largo):Promise<ResponseGenerico>
{
    return await Editar<Largo>("largo/CambiarEstado",largo,mainAxios);
} 

async function ObtenerLargo()
{
    return await Obtener<Largo>("largo/Obtener",mainAxios);
}


async function ObtenerSelectLargo()
{
    return await Obtener<Largo>("largo/ObtenerSelect",mainAxios);
}

export 
{
    Largo,
    GuardarLargo,
    EditarLargo,
    EditarEstadoLargo,
    ObtenerLargo,
    ObtenerSelectLargo
}