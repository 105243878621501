

















































































import {Largo,GuardarLargo,EditarLargo} from '@/entidades/Maestro/Ktb/Largo';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,decimal} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
@Component
export default class FormularioLargo extends Vue
{

  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Largo;

  descripcion?:string="";
  largoId?:number =0;
  pulgada?:number =0;
  pie?:number =0;
  pulgadaComercial:number=0;
  descripcionPulgada:string="";
  descripcionPulgadaComercial:string="";

  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
         pulgada : {decimal},
         pie : {decimal},
         pulgadaComercial : {required},
         descripcionPulgada :{required},
         descripcionPulgadaComercial :{required}

     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let largo:Largo = new Largo(this.largoId,this.descripcion,this.pulgada, this.pie,true,this.descripcionPulgada,this.pulgadaComercial,this.descripcionPulgadaComercial);
        //  console.log(largo);
         response = this.action == 1 ?  await GuardarLargo(largo) : await EditarLargo(largo);
         if(response.isSuccess == true)
         {
            this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
         this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
        this.changeLoading(new Loading(false,""));
     }
    
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE LARGO" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE LARGO" , 'boton' : "EDITAR"};
 }

 mounted() 
 {
    if(this.modelo!=undefined)
    {
        this.descripcion = this.modelo.descripcion;
        this.largoId = this.modelo.largoId;
        this.pie = this.modelo.pie;
        this.pulgada = this.modelo.pulgada;
        this.pulgadaComercial = this.modelo.pulgadaComercial!
        this.descripcionPulgada = this.modelo.descripcionPulgada!;
        this.descripcionPulgadaComercial = this.modelo.descripcionPulgadaComercial!;

    }   
 }

}
